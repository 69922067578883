@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #events,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

#contact {
  padding-top: 80;
  padding-bottom: 80;
}

#features {
  padding-top: 80;
  padding-bottom: 80;
}
.navbarContainer {
  background-color: transparent !important;
  box-shadow: none !important;
}
.overlay {
  height: 100vh !important;
  width: 100vw !important;
}
.intro h1 {
  font-family: sans-serif !important;
}
.events-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 800px) {
  .logo-img-container {
    display: none;
  }
  .title {
    font-size: 14 !important;
  }
  .donated-text {
    font-size: 7rem;
  }
  header .intro-text {
    padding-top: 200px;
  }
}

.event {
  cursor: pointer;
}

.eventModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  height: 90vh;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24;
}
